<template>
  <v-container class="mt-12">
    <v-row>
      <v-col>
        <span class="overline">Introducing</span>
        <p class="display-3 font-weight-black">VeriScan</p>
      </v-col>
    </v-row>
    <v-row no-gutters>
      <v-col cols="6">
        <p class="title">
          Industry leading SAAS + API solution for Know Your Customer (KYC) and Anti-Money
          Laundering (AML) compliance obligations.
        </p>
        <v-row class="mt-10">
          <v-col>
            <v-row>
              <v-spacer />
              <v-col>
                <v-icon color="green">mdi-clock-fast</v-icon>
              </v-col>
              <v-spacer />
            </v-row>
            <v-row>
              <v-col>
                Streamline and simplify third-party screening and resolution
                workflows
              </v-col>
            </v-row>
          </v-col>
          <v-col>
            <v-row>
              <v-spacer />
              <v-col>
                <v-icon color="blue">mdi-cog-refresh-outline</v-icon>
              </v-col>
              <v-spacer />
            </v-row>
            <v-row>
              <v-col>Automate ongoing Customer Due Diligence (CDD) compliance obligations</v-col>
            </v-row>
          </v-col>
          <v-col>
            <v-row>
              <v-spacer />
              <v-col>
                <v-icon color="purple">mdi-account-check-outline</v-icon>
              </v-col>
              <v-spacer />
            </v-row>
            <v-row>
              <v-col>
                Eliminate risk with the most up to date sanctions and regulatory
                lists from around the world including PEP, OFAC, EU, UN and more
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </v-col>
      <v-spacer />
      <v-col cols="4">
        <v-form ref="form" class="mx-2" lazy-validation>
          <v-card color="grey lighten-3">
            <v-card-title dark> Learn More </v-card-title>
            <v-card-text>
              
              <v-text-field 
                v-model="name"
                solo 
                dense 
                label="Name"
                :rules="[v => !!v || 'Name is required']" 
                required></v-text-field>


              <v-text-field
                v-model="email"
                solo 
                dense 
                label="Email"
                :rules="emailRules"
                required />

                <v-text-field
                  v-model="org"
                  solo 
                  dense 
                  label="Organization (optional)"
                />
              
              <v-text-field v-model="phoneNumber" solo dense label="Phone Number (optional)" />
              
              <v-textarea v-model="comments" label="Comments" solo></v-textarea>
            </v-card-text>
            <v-card-actions class="justify-center">
              <span v-if="submitted" class="green--text">Thanks, we'll be in touch!</span>
              <v-btn v-else rounded @click="contactFormSubmit" :disabled="!valid" :loading="loading">Submit</v-btn>
            </v-card-actions>
          </v-card>
        </v-form>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>

import axios from 'axios';
export default {
  name: "Home",
  components: {},
  data() {
    return {
      loading: false,
      submitted: false,
      valid: true,
      emailRules: [
        v => !!v || 'E-mail is required',
        v => /.+@.+\..+/.test(v) || 'E-mail must be valid',
      ],
      name: null,
      email: null,
      phoneNumber: null,
      org: null,
      comments: null
    }
  },
  methods: {
    contactFormSubmit() {
      if(this.$refs.form.validate()){
        // set loading true
        this.loading = true

        // make call
        axios.post('https://veriscan.verizent.com/contact',  
          {
            name: this.name,
            email: this.email,
            phoneNumber: this.phoneNumber,
            org: this.org,
            comments: this.comments
          })
          .then(() => {
            // handle success
            this.submitted = true
          })
          .catch(() => {
            // still handle success?
          })
          .finally(() => {
            this.loading = false
          })
      }
    }
  }
};
</script>

